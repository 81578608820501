import React, { useState, useEffect, useRef } from "react";
import "./Subnavbar.css";
import logo from "../../asset/vlogo1.jpg.webp";
import Topnavbar from "./Topnavbar";
import { Link } from "react-router-dom";

const Subnavbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null); // Active menu state
  const [activeDropdownChild, setActiveDropdownChild] = useState(null); // Active dropdown child state
  const dropdownRef = useRef(null); // Ref to the dropdown menu
  const navbarRef = useRef(null); // Ref for the navbar

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleDropdown = (index) => {
    setActiveDropdown((prev) => (prev === index ? null : index)); // Toggle dropdown visibility
  };

  const handleMenuClick = (index) => {
    setActiveMenu(index); // Set the active menu
    setActiveDropdownChild(null); // Reset active child when clicking parent
  };

  const handleDropdownChildClick = (parentIndex, childIndex) => {
    setActiveMenu(parentIndex); // Ensure parent stays active
    setActiveDropdownChild(childIndex); // Set active child
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
    setActiveDropdown(null);
  };

  const handleResize = () => {
    if (window.innerWidth > 1020) {
      setIsMobile(false);
      setActiveDropdown(null);
    }
  };

  // Handle hover out to close dropdown
  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  // Handle scroll to apply fixed navbar class
  const handleScroll = () => {
    if (navbarRef.current) {
      if (window.scrollY > navbarRef.current.offsetTop) {
        navbarRef.current.classList.add("fixed-navbar");
      } else {
        navbarRef.current.classList.remove("fixed-navbar");
      }
    }
  };

  useEffect(() => {
    // Close dropdown when clicking outside of it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener here
    document.addEventListener("mousedown", handleClickOutside); // Detect clicks outside the dropdown

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up scroll listener
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Topnavbar />

      <nav className="navigation" ref={navbarRef}>
        <div className="nav-container">
          <div className="brand">
            <a href="#!">
              <img src={logo} alt="Logo" className="logo-img" loading="lazy"/>
            </a>
          </div>
          <div className="nav-mobile" onClick={toggleMobileMenu}>
            <div id="nav-toggle" className={isMobile ? "active" : ""}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <ul className={`nav-list ${isMobile ? "active" : ""}`}>
            <div
              className={`close-btn ${isMobile ? "active" : ""}`}
              onClick={closeMobileMenu}
            >
              &times;
            </div>
            <li>
              <Link
                to="/"
                onClick={() => {
                  handleMenuClick(0);
                  closeMobileMenu();
                }}
                className={activeMenu === 0 ? "active" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  handleMenuClick(1);
                  closeMobileMenu();
                }}
                className={activeMenu === 1 ? "active" : ""}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/archive"
                onClick={() => {
                  handleMenuClick(2);
                  closeMobileMenu();
                }}
                className={activeMenu === 2 ? "active" : ""}
              >
                Archives
              </Link>
            </li>
            <li>
              <Link
                to="https://ecrop.co.in/"
                onClick={() => {
                  handleMenuClick(3);
                  closeMobileMenu();
                }}
                className={activeMenu === 3 ? "active" : ""}
                target="_blank"
              >
                e-CROP
              </Link>
            </li>
            <li>
              <Link
                to="/editorial"
                onClick={() => {
                  handleMenuClick(4);
                  closeMobileMenu();
                }}
                className={activeMenu === 4 ? "active" : ""}
              >
                Editorial Board
              </Link>
            </li>
            {/* Dropdowns for e-Content and Ethical Publishing Guide */}
            <li
              onMouseLeave={handleMouseLeave} // Close dropdown when mouse leaves
            >
              <Link
                to="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handleDropdown(0); // Unique index for this dropdown
                  handleMenuClick(5); // Ensure the dropdown parent gets active
                }}
                className={activeDropdown === 0 || activeMenu === 5 ? "active white-bg" : ""}
              >
                e-Content{" "}
                <span
                  className={`dropdown-icon ${activeDropdown === 0 ? "rotated" : ""}`}
                >
                  ▾
                </span>
              </Link>
              {activeDropdown === 0 && (
                <ul className="nav-dropdown" ref={dropdownRef}>
                  <li>
                    <Link
                      to="/e-journal"
                      onClick={() => {
                        handleDropdownChildClick(5, 0); // Make parent and child active
                        closeMobileMenu();
                      }}
                      className={activeDropdownChild === 0 ? "active" : ""}
                    >
                      e-Journal
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/e-magazine"
                      onClick={() => {
                        handleDropdownChildClick(5, 1); // Make parent and child active
                        closeMobileMenu();
                      }}
                      className={activeDropdownChild === 1 ? "active" : ""}
                    >
                      e-Magazine
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li
              onMouseLeave={handleMouseLeave} // Close dropdown when mouse leaves
            >
              <Link
                to="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handleDropdown(1); // Unique index for this dropdown
                  handleMenuClick(6); // Ensure the dropdown parent gets active
                }}
                className={activeDropdown === 1 || activeMenu === 6 ? "active white-bg" : ""}
              >
                Ethical Publishing Guide{" "}
                <span
                  className={`dropdown-icon ${activeDropdown === 1 ? "rotated" : ""}`}
                >
                  ▾
                </span>
              </Link>
              {activeDropdown === 1 && (
                <ul className="nav-dropdown" ref={dropdownRef}>
                  <li>
                    <Link
                      to="/publication"
                      onClick={() => {
                        handleDropdownChildClick(6, 0); // Make parent and child active
                        closeMobileMenu();
                      }}
                      className={activeDropdownChild === 0 ? "active" : ""}
                    >
                      Publication Ethics
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/guideline"
                      onClick={() => {
                        handleDropdownChildClick(6, 1); // Make parent and child active
                        closeMobileMenu();
                      }}
                      className={activeDropdownChild === 1 ? "active" : ""}
                    >
                      Guidelines for Author
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/submission"
                onClick={() => {
                  handleMenuClick(7);
                  closeMobileMenu();
                }}
                className={activeMenu === 7 ? "active" : ""}
              >
                Article Submission
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => {
                  handleMenuClick(8);
                  closeMobileMenu();
                }}
                className={activeMenu === 8 ? "active" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Subnavbar;
