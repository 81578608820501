import './App.css';
import React from 'react';
import Subnavbar from './Components/HeadFoot/Subnavbar';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Footer from './Components/HeadFoot/Footer';
import Editorial from './Components/Editorial/Editorial';
import Guideline from './Components/Guideline/Guideline';
import Publication from './Components/Guideline/publication';
import Submission from './Components/Submission/Submission';
import Submitarticle from './Components/Submission/Submitarticle';
import Contact from './Components/Contact/Contact';
import Archives from './Components/Archives/Archives';
import Scrolltotop from './Components/Scroll/Scrolltotop';
import EJournal from './Components/E_Content/E_journal/EJournal';
import EMagazine from './Components/E_Content/E_magazine/EMagazine';

// Define routes at the top level outside the App function
export const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/archive', component: Archives },
  { path: '/editorial', component: Editorial },
  { path: '/guideline', component: Guideline },
  { path: '/publication', component: Publication },
  { path: '/submission', component: Submission },
  { path: '/submitarticle', component: Submitarticle },
  { path: '/contact', component: Contact },
  { path: '/e-journal', component: EJournal },
  { path: '/e-magazine', component: EMagazine },
];

function App() {
  return (
    <>
      <BrowserRouter>
        <Subnavbar />
        <Scrolltotop />
        <Routes>
          {/* Dynamically create Route elements using the `routes` array */}
          {routes.map((route, index) => (
            <Route 
              key={index} 
              path={route.path} 
              element={<route.component />} // Correct way of passing component
            />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
