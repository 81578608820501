import React from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Magazine.css";
import Blog from "./Blog";
// import Title from "../Titleholder/Title";
import '../Sidebar/Sidebar'
import Sidebar from "../Sidebar/Sidebar";
import Title from "../Titleholder/Title";



const Magazine = () => {
  return (
    <>
      <div className="container carousel-container">
        
           {/* You can use any container class here */}
      <div className="row">
      <Title title="Featured Blogs" />

        <div className='col-lg-8'>
        <Blog columnClass="col-lg-12" viewMore={true} />{/* 1 column layout */}
        </div>
        <div className="col-lg-4">
          <Sidebar/>
        </div>
      </div>
    </div>

    </>
  );
};

export default Magazine;
