import React from "react";
import "./Cards.css";

import Downborder from "./Downborder";
import Titletag from "../Titletag/Titletag";

// Import images at the top
import amarbenPatniImg from "../../asset/Amarben-Patni.webp";
import RitaImg from "../../asset/rita.png";
import narendraImg from "../../asset/narendra.webp";
import rohitImg from "../../asset/Rohit.png";
import krishnaImg from "../../asset/Krishna.jpg";
import vandanaImg from "../../asset/vandana.webp";
import sayaliImg from "../../asset/Sayali.png";
import bhaktiImg from "../../asset/bhakti.jpg";
import swetaImg from "../../asset/sweta.png";
import snehalImg from "../../asset/snehal.webp";
import poojaImg from "../../asset/pooja.webp";

const Cards = () => {
  const edcards = [
    { name: "Amarben Patni", img: amarbenPatniImg, desig: "Chief Editor", email: "chiefeditor@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Vashi, Navi Mumbai)" },
    { name: "Rita Fredericks", img: RitaImg, desig: "Managing Editor", email: "managingeditor@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Bengaluru)"  },
    { name: "Narendra Nor", img: narendraImg, desig: "Publishing Manager", email: "editor@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Dombivali, Mumbai)"  },
    { name: "Rohit Sakpal", img: rohitImg, desig: "Sustainability Editor", email: "editor@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Bhandup, Mumbai)"  },
    { name: "Sweta Jha", img: swetaImg, desig: "Chief Content Ecologist", email: "sweta@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Kolkata)" },
    { name: "Krishna Shambhu Kanu", img: krishnaImg, desig: "Assistant Editor", email: "krishna@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Ghatkopar, Mumbai)"  },
    { name: "Vandana Prajapati", img: vandanaImg, desig: "Sustainable Web Developer", email: "vandana@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Haryana)" },
    { name: "Sayali Jadhav", img: sayaliImg, desig: "Eco-Friendly Designer", email: "sayali@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Wadala, Mumbai)" },
    // { name: "Kishor Das", img: raviImg, desig: "Eco-Friendly Designer", email: "ravi@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Thane)" },
    { name: "Bhakti Patil", img: bhaktiImg, desig: "Eco-Outreach Coordinator", email: "bhakti@ecroptoday.co.in", icon: "fa-solid fa-envelope", location: "(Nerul, Navi Mumbai)" },
  ];

  return (
    <>
      <div className="editorial-card">
        <div className="row">
          {edcards.map((cardE, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="card">
                <img src={cardE.img} alt={cardE.name} loading="lazy"/>
                <h5 className="mt-3">{cardE.name}</h5>
                <div className="cont">
                  <p className="mb-0 text-center">{cardE.desig}</p>
                  {cardE.email && (
                    <h6 className="m-0 p-1 text-success">
                      <i className={`${cardE.icon} mx-2`}></i>{cardE.email}
                    </h6>
                  )}
                  <h5 className="mb-0 text-center">{cardE.location}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Titletag icon="fa-solid fa-user-pen" title="EDITORIAL OFFICE" />
        <Downborder />
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <img src={snehalImg} alt="Snehal Nagwekar" loading="lazy"/>
              <h5 className="mt-3">Snehal Nagwekar</h5>
              <div className="cont">
                <p className="mb-0 text-center">Sustainability Manager</p>
                <h6 className="m-0 p-1 text-success"><i className="fa-solid fa-envelope mx-2"></i>editor@ecroptoday.co.in</h6>
                <h5 className="mb-0 text-center">(Kalyan, Mumbai)</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <img src={poojaImg} alt="Pooja Ankush Bhise" loading="lazy"/>
              <h5 className="mt-3">Pooja Ankush Bhise</h5>
              <div className="cont">
                <p className="mb-0 text-center">Sustainability Secretary</p>
                <h6 className="m-0 p-1 text-success"><i className="fa-solid fa-envelope mx-2"></i>editor@ecroptoday.co.in</h6>
                <h5 className="mb-0 text-center">(Parel, Mumbai)</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
